/**
 * @fileoverview Client-side feature flag and metrics tracking utilities using LaunchDarkly.
 * This module provides hooks and utilities for feature flag management and user behavior tracking
 * with built-in event queueing capabilities.
 */
import type { LDContext } from "launchdarkly-react-client-sdk";
import {
  asyncWithLDProvider,
  useLDClient as originalUseLDClient,
  useFlags,
} from "launchdarkly-react-client-sdk";
import { useCallback, useEffect, useRef } from "react";

// On the client, we check the value that was passed in via ENV.
// (Ensure that your getEnv() function passes ENABLE_LD to the client.)
const isLaunchDarklyEnabled =
  typeof window !== "undefined" &&
  window.ENV &&
  window.ENV.ENABLE_LD === "true";

/**
 * Type definition for generic metric data that can be attached to events
 * @typedef {Record<string, any>} MetricData
 */
type MetricData = Record<string, any>;

/**
 * Type for enforcing kebab-case naming convention
 * This ensures names like 'for-you-feed' are valid but 'for_you_feed' or 'for you feed' are not
 */
type KebabCase<T extends string> = T extends `${infer First}_${infer Rest}`
  ? never
  : T extends `${infer First} ${infer Rest}`
    ? never
    : T;

/**
 * Supported page types for analytics tracking
 * Add your application's page types here for better autocomplete
 */
export type PageType =
  | "opportunity-details"
  | "company-profile"
  | "post"
  | "search"
  | "profile"
  | "settings"
  | "pipeline"
  | string;

/**
 * Supported UI component types for state tracking
 */
export type UIComponentType =
  | "side-bar"
  | "modal"
  | "drawer"
  | "dropdown"
  | "tooltip"
  | "banner";

/**
 * Supported conversion types for business metrics
 */
export type ConversionType = "complete-signup" | "paywall" | string;

/**
 * Initialize the LaunchDarkly client provider
 * @param clientSideId - Your LaunchDarkly client-side ID
 * @param context - LaunchDarkly user context
 * @returns A configured LaunchDarkly provider component if enabled, otherwise returns a resolved promise
 * @example
 * ```tsx
 * const ldProvider = await initializeLDClient('client-123', {
 *   kind: 'user',
 *   key: 'user-123',
 *   name: 'John Doe',
 * });
 * ```
 */
export const initializeLDClient = async (
  clientSideId: string,
  context: LDContext
) => {
  if (!isLaunchDarklyEnabled) {
    return Promise.resolve();
  }
  return asyncWithLDProvider({
    clientSideID: clientSideId,
    context: context,
  });
};

/**
 * Hook for tracking user behavior and interactions.
 * All names must follow kebab-case convention (use hyphens, not underscores or spaces).
 * Examples: "for-you-feed", "share-modal", "export-button"
 *
 * @returns An object with methods to track different user actions
 *
 * @example
 * ```tsx
 * function MyComponent() {
 *   const track = useTrack();
 *
 *   useEffect(() => {
 *     track.pageView('home', { referrer: 'search' });
 *   }, []);
 *
 *   const handleClick = () => {
 *     track.buttonClick('submit-form', { formId: '123' });
 *   };
 *
 *   return <button onClick={handleClick}>Submit</button>;
 * }
 * ```
 */
export const useTracking = () => {
  if (!isLaunchDarklyEnabled) {
    console.warn("LD is not enabled");
    return {
      pageView: () => {},
      buttonClick: () => {},
      tabClick: () => {},
      featureUsed: () => {},
      loadTime: () => {},
      conversion: () => {},
      uiState: () => {},
    };
  }

  const client = originalUseLDClient();
  const eventQueueRef = useRef<
    Array<{ name: string; data?: MetricData; metricValue?: number }>
  >([]);
  const processingRef = useRef(false);

  const processQueue = useCallback(async () => {
    if (!client || processingRef.current || eventQueueRef.current.length === 0)
      return;

    processingRef.current = true;
    const events = [...eventQueueRef.current];
    eventQueueRef.current = [];

    try {
      await Promise.all(
        events.map(({ name, data, metricValue }) =>
          client.track(name, data, metricValue)
        )
      );
      console.log(`[LD Analytics] Processed ${events.length} queued events`);
    } catch (err) {
      console.error("Failed to send metrics batch:", err);
      eventQueueRef.current = [...eventQueueRef.current, ...events];
    } finally {
      processingRef.current = false;
      if (eventQueueRef.current.length > 0) {
        setTimeout(() => processQueue(), 0);
      }
    }
  }, [client]);

  const track = useCallback(
    (name: KebabCase<string>, data?: MetricData, metricValue?: number) => {
      if (typeof window === "undefined") return;

      eventQueueRef.current.push({ name, data, metricValue });
      if (!processingRef.current) {
        processQueue();
      }
    },
    [processQueue]
  );

  // Process queue when client becomes available
  useEffect(() => {
    if (client) {
      processQueue();
    }
  }, [client, processQueue]);

  return {
    track,
    pageView: (pageName: KebabCase<PageType>, metadata?: MetricData) => {
      track(`page-view.${pageName}`.toLowerCase(), metadata);
    },

    /**
     * Track button clicks with optional metadata
     * @param buttonName - The name of the button in kebab-case (e.g., "save-changes")
     */
    buttonClick: (buttonName: KebabCase<string>, metadata?: MetricData) => {
      track(`button-click.${buttonName}`.toLowerCase(), metadata);
    },

    /**
     * Track tab interactions with optional metadata
     * @param tabName - The name of the tab in kebab-case (e.g., "financial-details")
     */
    tabClick: (tabName: KebabCase<string>, metadata?: MetricData) => {
      track(`tab-click.${tabName}`.toLowerCase(), metadata);
    },

    /**
     * Track feature usage with optional metadata
     * @param featureName - The name of the feature in kebab-case (e.g., "export-to-excel")
     */
    featureUsed: (featureName: KebabCase<string>, metadata?: MetricData) => {
      track(`feature-used.${featureName}`.toLowerCase(), metadata);
    },

    /**
     * Track page load times with duration and optional metadata
     * @param pageName - The name of the page in kebab-case (e.g., "company-profile")
     */
    loadTime: (
      pageName: KebabCase<PageType>,
      duration: number,
      metadata?: MetricData
    ) => {
      track(`page-load-time.${pageName}`.toLowerCase(), metadata, duration);
    },

    /**
     * Track business conversions with optional metadata
     * @param type - The type of conversion in kebab-case (e.g., "complete-signup")
     */
    conversion: (type: KebabCase<ConversionType>, metadata?: MetricData) => {
      track(`conversion.${type}`.toLowerCase(), metadata);
    },

    /**
     * Track UI component states with optional metadata
     * @param component - The type of UI component in kebab-case (e.g., "side-bar")
     * @param componentName - The specific name of the component in kebab-case (e.g., "for-you-feed")
     * @param state - The state in kebab-case (e.g., "opened", "closed")
     */
    uiState: (
      component: KebabCase<UIComponentType>,
      componentName: KebabCase<string>,
      state: KebabCase<string>,
      metadata?: MetricData
    ) => {
      track(
        `ui-state.${component}.${componentName}.${state}`.toLowerCase(),
        metadata
      );
    },
  };
};

export { useFlags };
